import React, { useState } from "react";

const MessageForm = ({ message, onSuccess }) => {
  const [formData, setFormData] = useState(
    message || { subject: "", body: "" }
  );

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form submitted:", formData);
    onSuccess();
  };

  return (
    <form onSubmit={handleSubmit} className="p-6 rounded-lg shadow-md mb-6">
      <h2 className=" font-bold mb-4 ">Message Form</h2>
      <div className="mb-4">
        <label className="block  mb-2" htmlFor="subject">
          Subject
        </label>
        <input
          type="text"
          id="subject"
          name="subject"
          value={formData.subject}
          onChange={handleChange}
          className="w-full px-3 py-2 border  rounded"
          required
        />
      </div>
      <div className="mb-4">
        <label className="block  mb-2" htmlFor="body">
          Body
        </label>
        <textarea
          id="body"
          name="body"
          value={formData.body}
          onChange={handleChange}
          className="w-full px-3 py-2 border  rounded"
          required
        />
      </div>
      <button type="submit" className="  px-4 py-2 rounded ">
        Submit
      </button>
    </form>
  );
};

export default MessageForm;
